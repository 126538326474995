
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        




















.cta {
  position: relative;
  margin-top: $spacing;

  @include mq(m) {
    margin-top: $spacing * 2;
  }

  @include mq(l) {
    margin-top: $spacing * 3;
  }
}

.cta__content {
  @include mq(m) {
    display: flex;

    > * {
      flex-basis: 50%;
    }
  }
}

.cta__content__text {
  padding: $spacing $spacing * 1.5;
  color: $c-white;
  background-color: $c-blue-medium;

  @include mq(m) {
    font-size: 1.8rem;
  }
}

.cta__content__btn {
  width: 100%;
  transform: translateX(3.1rem);

  &::v-deep {
    .btn__label {
      width: 100%;
    }

    .btn__icon {
      transform: translateX(-100%);
    }
  }

  @include mq(m) {
    position: relative;
    justify-content: center;
    gap: 2rem;
    overflow: hidden;
    border: 1px solid $c-blue-medium;
    transform: translateX(0);

    &::v-deep {
      .btn__label {
        position: initial;
        left: 0;
        display: flex;
        align-items: center;
        width: auto;
        height: 100%;
        border: 0;
      }

      .btn__icon {
        position: relative;
        height: 6.2rem;
        fill: $c-blue-medium;
        background-color: $c-transparent;
      }
    }
  }
}

.cta__content__author {
  // margin-top: $spacing * 2;
  // font-size: 16px;
  // font-style: normal;
  // font-weight: 700;
  // line-height: 24px; /* 150% */
  // text-align: center;
  // letter-spacing: 1px;
}
