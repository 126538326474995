
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        




















.app {
  font-family: $ff-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0;
}

.app__header {
  position: relative;
}
