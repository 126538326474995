
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        










.quote {
  position: relative;
  margin-top: $spacing * 2;
  margin-inline: -$spacing * 2;
  padding: $spacing $spacing * 2;
  background-color: $c-gray-lightest;

  @include mq(m) {
    padding: $spacing * 2 $spacing * 3;
    margin-inline: -$spacing * 5;
  }

  @include mq(l) {
    margin-top: $spacing * 3;
    padding: $spacing * 3 $spacing * 5;
    margin-inline: -$spacing * 10;
  }
}

.quote__content {
  @extend %text-center;

  color: $c-blue-dark;
}

.quote__content__text {
  font-style: normal;
  font-weight: 400;
}

.quote__content__author {
  margin-top: $spacing * 2;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  text-align: center;
  letter-spacing: 1px;
}
