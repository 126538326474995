
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        









.date__label {
  @extend %fw-bold;

  margin-top: $spacing * 1.5;
  color: $c-gray-lighter;
  font-size: 1.4rem;
}
