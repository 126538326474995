
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        















.link,
[class*='link--'] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  text-decoration: none;
  transform: translateX(-2px);

  &[class*='--reverse'] {
    flex-direction: row-reverse;
  }

  &:hover {
    .link__label {
      transform: translateX(25%);
    }
  }
}

.link__label {
  @extend %fw-bold;
  @extend %text-uppercase;

  position: relative;
  display: inline-block;
  color: $c-blue-medium;
  font-size: 1.4rem;
  line-height: 1.2;
  letter-spacing: 0.8px;
  transition: transform 0.2s ease-out;

  &::after {
    @include center-y;

    content: '';
    left: calc(100% + 2rem);
    display: block;
    width: 5rem;
    height: 0.1rem;
    background-color: $c-blue-medium;
    transform: translate(0, 100%) scaleX(1);
    transform-origin: left;
    will-change: opacity, transform;
    transition: 0.3s $ease-out-quad;
    transition-property: opacity, transform;
  }

  &::before {
    @include center-y;

    content: '';
    right: calc(100% + 2rem);
    display: block;
    width: 5rem;
    height: 0.1rem;
    background-color: $c-blue-medium;
    transform: translate(0, 100%) scaleX(0);
    transform-origin: right;
    will-change: opacity, transform;
    transition: 0.3s $ease-out-quad;
    transition-delay: 0.1s;
    transition-property: opacity, transform;
  }

  :hover > & {
    &::after {
      // opacity: 0;
      transform: translate(0, 100%) scaleX(0);
      transform-origin: right;
      transition-delay: 0.1s;
    }

    &::before {
      // opacity: 1;
      transform: translate(0, 100%) scaleX(1);
      transform-origin: left;
      transition-delay: 0;
    }
  }

  [class*='link--'][class*='--light'] & {
    color: $c-blue-light;

    &::before,
    &::after {
      background-color: $c-blue-light;
    }
  }

  @include mq($until: m) {
    padding-bottom: $spacing / 2;
    border-bottom: 1px solid $c-blue-medium;

    &::before,
    &::after {
      display: none;
    }
  }
}

.link__icon {
  width: 2.4rem;
  height: 2.4rem;
}
