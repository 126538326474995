
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        
























.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.not-found-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.not-found__picture {
  margin: 0 auto;

  @include mq(m) {
    width: 60%;
  }
}
