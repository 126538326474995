
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        































































































.menu-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: $spacing * 1.5;

  @include mq(l) {
    width: 100%;
  }
}

.menu__adress__title {
  @extend %fw-bold;
}

.menu__adress__items {
  display: flex;
  flex-direction: column;
}

.menu__adress__item {
  line-height: 1.5;
}

.menu__adress__logo {
  width: 60%;
  height: 50%;

  svg {
    width: 100%;
    height: 100%;
    fill: $c-blue-dark;
  }
}

.menu__adress__socials {
  margin-top: auto;

  .social-network__item__icon {
    fill: $c-blue-medium !important; /* stylelint-disable-line declaration-no-important */
  }

  * {
    fill: $c-blue-medium !important; /* stylelint-disable-line declaration-no-important */
  }
}

.menu__categories {
  display: flex;
  flex-direction: column;

  @include mq($until: l) {
    display: none;
  }
}

a {
  color: $c-blue-dark;
  text-decoration: none;
  transition: all 0.3s;

  &:hover {
    color: $c-blue-medium;
  }
}

.menu__category {
  width: 100%;
  padding: 0;
  color: $c-blue-dark;

  & + & {
    margin-top: 0.5rem;
  }

  &.small {
    line-height: 1.5;
  }
}
