
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        











































.btn,
[class*='btn--'] {
  position: relative;
  display: inline-flex;
  // justify-content: center;
  align-items: center;
  text-decoration: none;

  &[class*='--reverse'] {
    flex-direction: row-reverse;
  }

  &:hover {
    .btn__label {
      color: $c-white;
    }

    .btn__label::after {
      transform: translateX(0%);
    }

    .btn__icon {
      fill: $c-white;
    }
  }
}

.btn-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: $spacing * 2 $spacing $spacing;
}

.btn__label {
  @extend %text-uppercase;
  @extend %fw-bold;

  position: relative;
  z-index: 1;
  left: -3.1rem;
  display: inline-block;
  overflow: hidden;
  // width: calc(100% - 6.2rem);
  padding: $spacing * 0.8 $spacing * 1.5;
  color: $c-blue-medium;
  font-size: 1.4rem;
  border: 1px solid $c-blue-medium;
  transition: color 0.2s ease-out;

  [class*='btn--'][class*='--light'] {
    color: $c-blue-light;
    border: 1px solid $c-blue-light;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    display: block;
    width: 200%;
    height: 110%;
    background-color: $c-blue-medium;
    transform: translateX(-100%);
    clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 62% 100%);
    transform-origin: top;
    transition: transform 0.2s ease-out;

    @include mq($until: l) {
      display: none;
    }
  }
}

.btn__icon {
  position: absolute;
  z-index: 3;
  right: -3.1rem;
  width: 6.2rem;
  height: 100%;
  padding: 1rem;
  fill: $c-white;
  background-color: $c-blue-medium;
  transition: background-color 0.2s ease-out;

  [class*='btn--'][class*='--light'] {
    background-color: $c-blue-light;
  }
}
