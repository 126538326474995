
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        






















































.nav {
  text-align: left;

  ul {
    padding: 0;
  }

  li {
    list-style: none;
  }
}

.nav-block,
[class*='nav-block--'] {
  position: relative;
  display: flex;
  height: $header-height;
  line-height: $header-height;

  @include mq($header-breakpoint) {
    height: $header-height-large;
    line-height: $header-height-large;
  }
}

.nav-block__item {
  @extend %text-uppercase;
  @extend %fw-bold;

  position: relative;
  display: flex;
  margin: 0 $spacing * 1.5;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    bottom: 5rem;
    left: 0;
    display: block;
    width: 100%;
    height: 0.2rem;
    background-color: $c-white;
    transform: translate(0, 100%) scaleX(0);
    transform-origin: left;
    will-change: opacity, transform;
    transition: 0.3s $ease-out-quad;
    transition-property: opacity, transform;
  }

  &:hover {
    &::after {
      transform: translate(0, 100%) scaleX(1);
      transform-origin: right;
      transition-delay: 0.1s;
    }
  }

  > span {
    display: flex;
    align-items: center;
  }

  > * {
    color: $c-white;
    text-decoration: none;

    .is-profile &,
    .is-notfound & {
      color: $c-blue-dark;
    }
  }

  &:last-child {
    margin-right: 0;
    // background-color: $c-blue-light;
    &::after {
      display: none;
    }

    > * {
      position: relative;
      display: flex;
      align-items: center;
      overflow: hidden;
      height: 100%;
      padding: 0 0 0 $spacing * 2.5;
      color: $c-blue-dark;
      // background-color: $c-blue-light;

      &::before {
        content: '';
        position: absolute;
        z-index: -2;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $c-blue-light;
      }

      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 30vw;
        background-color: $c-white;
        transform: rotate(-45deg) translateY(-130%) translateX(-20%);
        transform-origin: top;
        transition: transform 0.2s ease-out;
      }

      &:hover {
        /* stylelint-disable-next-line max-nesting-depth */
        &::after {
          transform: rotate(-45deg) translateY(-60%) translateX(-20%);
        }
      }

      .is-profile & {
        // color: $c-white;
      }

      .icon {
        position: relative;
        z-index: 9;
        fill: $c-white;
        width: 10rem;
        height: 100%;
        margin-left: $spacing * 2.5;
        padding: $spacing * 1.5;
        background-color: $c-blue-light;
      }
    }
  }
}

.nav-block__item__arrow {
  margin-left: 1rem;
  fill: $c-blue-light;
}

.nav__list,
[class*='nav__list--'] {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 30rem;
  line-height: 1;
  background-color: $c-white;

  .is-profile &,
  .is-page & {
    background-color: $c-blue-light;
  }

  &::before {
    @include center-x;

    content: '';
    top: 0;
    display: block;
    width: 2rem;
    height: 2rem;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: $c-white;

    .is-profile &,
    .is-page & {
      background-color: $c-blue-light;
    }
  }
}

.nav__item {
  a {
    position: relative;
    display: block;
    padding: 2.5rem 3.6rem;
    color: $c-blue-dark;
    font-size: 0.9em;
    font-weight: 700;
    text-decoration: none;
    transition: 0.2s ease-out;
    transition-property: color, background-color;

    .is-profile &,
    .is-page & {
      color: $c-white;
    }

    &:hover {
      color: $c-blue-light;
      background-color: $c-gray-lightest;

      &::after {
        transform: translate(0, 100%) scaleX(1);
        transform-origin: right;
        transition-delay: 0.1s;
      }

      .is-profile &,
      .is-page & {
        color: $c-blue-light;
        background-color: $c-blue-dark;
      }
    }

    &.router-link-exact-active {
      color: $c-blue-light;
      background-color: $c-gray-lightest;
      pointer-events: none;
    }
  }
}

[class*='nav__list--'][class*='--pages'] {
  .nav__item {
    + .nav__item::before {
      content: ' > ';
      font-weight: 700;
    }

    a {
      &.router-link-active {
        color: $c-blue-light;
      }
    }
  }
}
