
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        

















































































































































.contact-infos-inner {
  width: 100%;
  height: 100%;

  @include mq(m) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .is-reverse & {
      flex-direction: row-reverse;
    }
  }
}

.contact-infos__picture {
  &::v-deep.picture {
    height: 25rem;

    @include mq($until: m) {
      margin-right: 4rem;
    }

    @include mq(m) {
      flex-basis: 45%;
      height: 60rem;
    }
  }

  &::v-deep .picture-inner {
    object-position: center 20%;
  }
}

.contact-infos__link {
  position: absolute;
  z-index: 1;
  top: $spacing * 5;
  left: $spacing;

  &::v-deep {
    flex-direction: row-reverse;

    .link__label {
      padding-bottom: 0;
      color: $c-blue-dark;
      border-bottom: none;

      &::after,
      &::before {
        display: none;
      }
    }

    .link__icon {
      fill: $c-blue-dark;
      transform: rotate(90deg);
    }
  }

  @include mq(l) {
    top: $spacing * 8;
    left: $spacing * 2;
  }
}

.contact-infos__content {
  position: relative;
  padding: $spacing * 3 $spacing;

  @include mq($until: m) {
    display: flex;
    flex-direction: column;
  }

  @include mq(m) {
    flex-basis: 50%;
    padding: $spacing * 3;
  }

  @include mq(l) {
    padding: $spacing * 5;
  }
}

.contact-infos__content__adress-outer {
  max-width: 42rem;
  margin-top: $spacing;
  border-top: 1px solid $c-gray-lighter;
}

.contact-infos__content__adress {
  display: flex;
  gap: $spacing;
  margin-top: $spacing;
}

.contact-infos__content__text {
  & + & {
    margin-top: 0;
  }
}

.contact-infos__content__job {
  margin-top: $spacing;
}

.contact-infos__content__mail,
.contact-infos__content__phone {
  display: flex;
  align-items: center;
  gap: $spacing;
  margin-top: $spacing / 2;
  line-height: 1;

  > * {
    // flex-basis: 50%;
    // margin-right: $spacing;
    text-decoration: none;
  }
}

.contact-infos__content__quote {
  top: 0;
  left: 50%;
  overflow: hidden;
  padding: 4rem;
  color: $c-white;
  font-weight: 400;
  background-color: $c-blue-medium;
  // transform: translate(-50%, -80%);

  @include mq($until: m) {
    order: -1;
    width: 100%;
    transform: translateY(-15rem);
    margin-bottom: -10rem;
  }

  @include mq(m) {
    @include center-y;

    top: 50%;
    left: 0;
    max-width: 30rem;
    transform: translate(-100%, 0%);
  }
}

.contact-infos__content__quote__author {
  position: relative;
  display: block;
  margin-top: $spacing * 4;
  padding-left: $spacing * 0.5;
  color: $c-white;
  font-weight: 700;

  &::after {
    @include center-y;

    content: '';
    left: 0;
    display: block;
    width: 0.4rem;
    height: 0.4rem;
    background-color: $c-white;
    border-radius: 0.4rem;
    transform: translate(0, -30%);
  }
}

.tag-rounded {
  display: block;
  width: fit-content;
  margin-top: 3rem;
}
