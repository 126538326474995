
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        


























.collapses {
  position: relative;
}

.collapses__intro {
  background-color: $c-gray-lightest !important;
}

.collapses__items {
  max-width: 100rem;
  margin: 0 auto;
}

.collapses__item {
  width: 100%;

  & + & {
    margin-top: -1px;
  }
}

.collapses__btn-outer {
  display: flex;
  justify-content: center;
}

.collapses__btn {
  display: inline-flex;
  margin-top: $spacing;

  @include mq(m) {
    margin-top: $spacing * 4;
  }
}
