
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        





















.gallery__list {
  position: relative;
  width: 100%;
  height: 100% !important; // stylelint-disable-line declaration-no-important
  background-color: $c-gray-light;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (9 / 16) * 100%;
  }
}

.gallery__item {
  @include get-all-space;

  opacity: 0;

  &:first-child {
    z-index: 2;
    opacity: 1;
  }
}

.gallery__item__picture {
  @include image-fit;
}

.gallery__caption-inner {
  @extend %text-center;

  width: 100%;
  padding: $spacing * 1.5;
  color: $c-gray-dark;
  font-size: 1.4rem;
  line-height: 1.2em;
  background-color: $c-gray-lighter;

  @include mq(xxl) {
    width: col(14);
    margin: 0 col(1);
  }
}

.gallery-slidy-outer {
  position: relative;

  @include mq(xxl) {
    width: col(14);
    margin: 0 col(1);
  }
}

[class*='gallery-slidy-controls__item--'] {
  @include center-y;

  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  background-color: $c-white;
  border: 2px solid $c-gray-light;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: $c-blue-dark;
    border-color: $c-blue-dark;
  }

  &[class*='--prev'] {
    left: col(1);

    @include mq(xxl) {
      left: col(0.5, 14);
    }
  }

  &[class*='--next'] {
    right: col(1);

    @include mq(xxl) {
      right: col(0.5, 14);
    }
  }

  @include mq(s) {
    width: 50px;
    height: 50px;
  }
}

.gallery-slidy-controls__item__icon {
  display: block;
  width: 14px;
  fill: $c-blue-dark;

  [class*='gallery-slidy-controls__item--']:hover & {
    fill: $c-white;
  }

  [class*='gallery-slidy-controls__item--'][class*='--prev'] & {
    transform: rotate(90deg) translateY(2px);
  }

  [class*='gallery-slidy-controls__item--'][class*='--next'] & {
    transform: rotate(-90deg) translateY(2px);
  }

  @include mq(s) {
    width: 18px;
  }
}
