
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        











































































































$margin: 10rem;

.video-outer {
  position: relative;
  background-color: $c-white;

  @include mq(m) {
    display: flex;
    justify-content: space-between;
  }
}

.video {
  transform: translateY(-$margin);

  .wysiwyg & {
    transform: 0;
  }

  @include mq($until: m) {
    margin: 0 !important;

    &::v-deep {
      .btn__label {
        display: none;
      }

      .btn__icon {
        width: 6.2rem;
        height: 6rem;
      }
    }
  }

  @include mq(m, xl) {
    width: 100%;
  }
}

.video-inner {
  position: relative;
  cursor: pointer;
  background-color: $c-blue-dark;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (9 / 16) * 100%;
  }
}

.video__title {
  @extend %text-center;

  margin-bottom: $spacing * 2.5;
  color: $c-blue-dark;
}

.video__highlight {
  width: 100%;
  margin-bottom: -$spacing * 2;

  &.is-solo {
    .video__icon {
      @include center-xy;

      width: 60px;
      height: 60px;
    }
  }

  @include mq($until: m) {
    margin-top: $spacing * 3;
    margin-bottom: -$spacing * 2;
  }
}

.video__items {
  flex-shrink: 0;
  flex-basis: 51%;
  width: 100%;

  @include mq(m) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: col(1);
  }
}

.video__item {
  position: relative;
  flex-basis: 44%;
  width: 100%;
  cursor: pointer;
  margin-bottom: $spacing * 3;
  background-color: $c-blue-dark;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (9 / 16) * 100%;
  }

  .video__icon {
    right: $spacing;
    left: inherit;

    @include mq(s) {
      right: $spacing * 1.5;
    }
  }
}

.video__poster {
  @include get-all-space;

  background-color: $c-black;
}

.video__poster__picture {
  @include image-fit;

  opacity: 0.3;
  transition: opacity 0.2s ease-out;

  .video-inner:hover & {
    opacity: 0.5;
  }
}

.video__content {
  @extend %ff-alt;

  position: absolute;
  z-index: 2;
  bottom: $spacing;
  left: $spacing;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - #{$spacing * 2});
  max-width: 340px;
  color: $c-white;

  @include mq(m) {
    bottom: $spacing * 2;
    left: $spacing * 2;
    width: calc(100% - #{$spacing * 4});
    max-width: 600px;
  }

  @include mq(l) {
    bottom: $spacing * 3;
    left: $spacing * 3;
    width: calc(100% - #{$spacing * 6});
    max-width: 600px;
  }
}

.video__content__category {
  @extend %text-uppercase;
  @extend %fw-bold;

  padding: $spacing / 2;
  font-size: 1.2rem;
  line-height: 1em;
  white-space: nowrap;
  background-color: $c-blue-light;
}

.video__content__title {
  @extend %fw-bold;

  margin-top: $spacing;
  font-size: 2.4rem;
  line-height: 1.2em;

  @include mq(m) {
    font-size: 4.2rem;
    line-height: 1.2em;
  }
}

.video__btn {
  @include center-xy;

  ::v-deep {
    .btn__label {
      background-color: $c-white;
    }
  }
}
