// Import fonts

// stylelint-disable
@font-face {
  font-family: 'sofia-pro';
  font-style: normal;
  font-weight: 400;
  src: url('./../assets/fonts/sofia-pro-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'sofia-pro';
  font-style: normal;
  font-weight: 700;
  src: url('./../assets/fonts/sofia-pro-bold.ttf') format('truetype');
}
// stylelint-enable

a {
  color: $c-blue-dark;

  &:hover {
    text-decoration: none;
  }
}

.htmltext {
  a {
    font-size: 1.4rem;
    transition: color 0.1s ease-out;

    &:hover {
      color: $c-blue-light;
    }

    @include mq(s) {
      font-size: 1.6rem;
    }
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  font-family: $ff-default;
}

h1,
.h1,
[class*='h1--'] {
  @extend %fw-bold;

  color: $c-blue-dark;
  font-size: 3.6rem;
  line-height: 1.2;

  &[class*='--light'] {
    color: $c-blue-light;
  }

  &[class*='--white'] {
    color: $c-white;
  }

  &[class*='--left'] {
    text-align: left;
  }

  @include mq(xl) {
    font-size: 6.4rem;
  }
}

h2,
.h2,
[class*='h2--'] {
  @extend %fw-bold;

  color: $c-blue-dark;
  font-size: 2.4rem;
  line-height: 1.15;
  letter-spacing: -1px;

  strong {
    @extend %text-uppercase;
  }

  &[class*='--white'] {
    color: $c-white;
  }

  @include mq(l) {
    font-size: 4.8rem;
  }
}

h3,
.h3 {
  @extend %fw-bold;

  color: $c-blue-dark;
  font-size: 2.4rem;
  line-height: 1.33;
  letter-spacing: -1px;

  strong {
    @extend %text-uppercase;
  }

  @include mq(l) {
    font-size: 2.8rem;
  }

  @include mq(xxl) {
    font-size: 3.6rem;
  }
}

h4,
.h4 {
  @extend %fw-bold;

  color: $c-blue-dark;
  font-size: 1.8rem;
  line-height: 1.2;

  strong {
    @extend %text-uppercase;
  }

  @include mq(l) {
    font-size: 2.4rem;
  }
}

h5,
.h5 {
  @extend %fw-bold;

  font-size: 2rem;
  line-height: 1.5;
}

h6,
.h6 {
  font-size: 1.6rem;
  line-height: 1.5;
}

body {
  @extend %fw-normal;

  font-family: $ff-default;
  font-size: 1.6rem;
  line-height: 1.5;
}

.headline,
[class*='headline--'] {
  @extend %fw-bold;
  @extend %text-uppercase;

  color: $c-blue-dark;
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: 0.8px;

  &[class*='--light'] {
    color: $c-blue-light;
  }

  @include mq(l) {
    font-size: 1.6rem;
  }
}

.quote {
  @extend %fw-normal;

  font-family: $ff-default;
  font-size: 2.2rem;
  line-height: 1.4;

  strong {
    @extend %fw-bold;
  }

  @include mq(l) {
    font-size: 2.8rem;
  }

  @include mq(xxl) {
    font-size: 3.2rem;
  }
}

.lead,
[class*='lead--'] {
  @extend %fw-normal;

  color: $c-black;
  font-size: 1.8rem;
  line-height: 1.5;

  &[class*='--medium'] {
    color: $c-blue-medium;
  }

  @include mq(l) {
    font-size: 2.4rem;
  }
}

.small {
  @extend %fw-normal;

  font-family: $ff-default;
  font-size: 1.4rem;
  line-height: 2;
}

.tag-rounded {
  padding: 0.3rem 1rem 0.5rem 1rem;
  color: #a0a0a0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: normal;
  border: 1px solid #a0a0a0;
  border-radius: 2.4rem;
}
