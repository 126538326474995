
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        























.social-network {
  display: flex;
  margin-top: $spacing * 1.5;
  text-align: center;
}

.social-network__items {
  display: flex;
  align-items: center;
  list-style: none;
}

.social-network__item {
  position: relative;
  width: 4.4rem;
  height: 4.4rem;
  border: 1px solid $c-blue-medium;
  border-radius: 50%;
  transition: border-color 0.2s ease-out;

  & + & {
    margin-left: 1rem;
  }

  &:hover {
    border: 1px solid $c-blue-light;
  }
}

.social-network__item-inner {
  @include get-all-space;
}

.social-network__item__icon {
  @include center-xy;

  fill: $c-white;
  padding: 0.5rem;
  transition: fill 0.2s ease-out;

  .medium & {
    fill: $c-blue-medium;
  }

  .social-network__item:hover & {
    fill: $c-blue-light;
  }
}
