
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        




































$w: 1.3rem;
$h: 1.1rem;
$s: 1.5;
$p: 0.6rem;
$bg: $c-dev-bg;
$c: $c-white;

.env-switcher {
  position: fixed;
  z-index: 9999;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 0 $p 0 0;
  background-color: $bg;
  transform: translateX(calc(100% - #{(($w * $s) + (2 * $p))}));

  &.is-open {
    transform: translateX(0);
  }
}

.env-switcher__toggler {
  padding: $p;
  color: $c;
  line-height: 1;
  background-color: $bg;
  border: none;
  fill: $c;

  svg {
    width: $w * $s;
    height: $h * $s;
    fill: inherit;
  }
}

.env-switcher__select {
  color: $c;
  font-size: 0.8em;
  background-color: $bg;
  border: none;
}
