@if $env != 'production' {
  // show all breakpoints
  $mq-show-breakpoints: map-keys($mq-breakpoints);

  @include mq-show-breakpoints($mq-show-breakpoints);
}

body {
  > svg {
    display: none;
  }
}

pre {
  margin: 1rem 1rem 0.5rem;
  padding: 0.5em;
  font-size: 0.75em;
  text-align: left;
  border: 1px dashed rgba($c-dev-bg, 0.5);
}
