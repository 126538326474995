
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        






























.intro {
  background-color: $c-white;
}

.intro-inner {
  @extend %text-center;

  max-width: 80rem;
  padding: $spacing * 2 0;

  @include mq(m) {
    margin: 0 auto;
    padding: $spacing * 3.5 0;
  }

  @include mq(l) {
    padding: $spacing * 5 0;
  }
}

.intro__title {
  .intro__headline + & {
    margin-top: $spacing;
  }
}

.intro__text {
  margin-top: $spacing * 1.5;
}

.intro__btn-outer {
  display: flex;
  justify-content: center;
}

.intro__btn {
  display: flex;
  margin-top: $spacing;

  @include mq(m) {
    margin-top: $spacing * 4;
  }
}
