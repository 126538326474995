//Avoid typekit FOUT
html {
  &.js {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &.wf-active,
    &.wf-inactive,
    &.ready {
      opacity: 1;
    }
  }
}

// SVG sprite
body > svg {
  display: none;
}

// RWD
img {
  max-width: 100%;
  height: auto;
}

// section {
//   margin: 2rem;
//   padding: 1rem;
//   border: 1px dotted $c-gray-light;
// }

hr {
  height: 1px;
  background-color: $c-gray-lighter;
  border: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

// Global Colors

.is-white {
  background-color: $c-white !important;
}

.is-gray-lightest {
  background-color: $c-gray-lightest !important;
}