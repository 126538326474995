
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        


















.author {
  padding: $spacing * 2 0;
}

.author-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.author__picture {
  &::v-deep.picture {
    width: 8rem;
    height: 8rem;
    min-height: 0;
    margin-right: $spacing;
    border-radius: 8rem;
  }
}
