
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        


























.footer {
  position: relative;
  overflow: hidden;
  padding: $spacing * 2;
  background-color: $c-gray-lightest;

  @include mq(m) {
    padding: $spacing * 4;
  }

  @include mq(xl) {
    padding: $spacing * 6;
  }
}

.footer-inner {
  display: flex;
  flex-direction: column-reverse;
  max-width: $content-width;
  color: $c-blue-dark;

  @include mq(l) {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
  }
}

.footer__main {
  width: 100%;
  padding: 0;

  @include mq($until: l) {
    flex-direction: row-reverse;
  }

  @include mq(l) {
    width: 60%;
    padding: $spacing * 2 0 $spacing * 4;
    border-bottom: 0;
  }
}

.footer__main__logo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .icon {
    margin-right: $spacing * 3;

    @include mq($until: l) {
      position: absolute;
      right: 0;
      bottom: 40px;
      margin-right: $spacing * 2;
    }
  }

  @include mq($until: m) {
    width: 50%;
  }
}

.footer__aside {
  width: 100%;

  @include mq(l) {
    width: 38%;
    padding-right: 0;
    padding-left: 0;
  }

  @include mq(xl) {
    width: 33%;
  }
}

.footer__lottie {
  @include mq($until: m) {
    transform: translateX(-30px);
  }

  @include mq(m) {
    height: 36rem;
  }
}

.footer__epic__logo {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  fill: $c-blue-medium;
  opacity: 0.3;
}
