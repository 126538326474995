
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        





















.header {
  @include center-x;

  z-index: 10;
  top: 0;
  width: 100%;
  height: $header-height;

  .is-page & {
    background-color: $c-blue-medium;
  }

  .first-load.is-front-page & {
    opacity: 0;
  }

  @include mq($header-breakpoint) {
    height: $header-height-large;
  }
}

.header-inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.header__nav {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;

  @include mq($until: xl) {
    display: none;
  }
}

.header__nav__logo {
  position: relative;
  z-index: 2;
  display: block;
  margin-top: 1rem;
  margin-left: 3rem;

  svg {
    width: 12rem;
    height: auto;
    fill: $c-white;

    .is-notfound &,
    .is-profile & {
      fill: $c-blue-dark;
    }
  }

  .is-front-page & {
    pointer-events: none;
  }

  @include mq(l) {
    width: 20rem;
    margin-left: 4rem;
  }
}

.header__mega {
  z-index: 1;

  @include mq(xl) {
    display: none;
  }
}
