
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        


















.related-projects {
  position: relative;
  padding-bottom: $spacing * 2;
  background-color: $c-white;

  @include mq(m) {
    padding-bottom: $spacing * 3.5;
  }

  @include mq(l) {
    padding-bottom: $spacing * 5;
  }
}

.related-projects__items {
  display: flex;
  flex-wrap: wrap;

  &::v-deep .card {
    max-width: 45rem;
  }

  @include mq(s) {
    margin: -2%;
  }

  @include mq(l) {
    margin: -1%;
  }
}

.related-projects__item {
  width: 100%;
  margin: 5% 0;

  @include mq(s) {
    width: calc(92% / 2);
    margin: 2%;
  }

  @include mq(l) {
    width: calc(94% / 3);
    margin: 1%;
  }
}
