
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        


















.hero {
  height: 30vh;

  @include mq(m) {
    height: 50vh;
  }
}

.hero__title {
  line-height: 1.1;

  &::v-deep {
    > div {
      padding: 1rem;
    }
  }

  @include mq(m) {
    line-height: 1.2;
  }

  @include mq($until: m) {
    margin: 0 $spacing;
  }
}

.hero-inner {
  @extend %text-center;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 96rem;
  height: 100%;
  margin: 0 auto;
}
