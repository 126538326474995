
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        























.duo-inner {
  @include mq(m) {
    display: flex;
    justify-content: space-between;

    .is-reverse & {
      flex-direction: row-reverse;
    }
  }
}

.duo__picture {
  &::v-deep.picture {
    height: 40rem;
  }

  @include mq(m) {
    flex-basis: 50%;
    height: 60rem !important;
    // height: auto;
  }
}

.duo__content {
  padding: $spacing;

  @include mq(m) {
    flex-basis: 50%;
    padding: $spacing * 3;
  }

  @include mq(l) {
    padding: $spacing * 5;
  }
}

.duo__content__text {
  margin-top: 2em;
  // color: $c-white;
}

.duo__content__link {
  margin-top: 2em;
}
