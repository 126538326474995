html {
  font-size: calc(1em * 0.625);
}

body {
  /* sass-lint:disable-block no-vendor-prefixes */
  color: $c-gray-darkest;
  font-size: $body-text-s;
  font-weight: 300;
  line-height: 1.5;

  @include mq('m') {
    font-size: $body-text-m;
  }

  @include mq('l') {
    font-size: $body-text-l;
  }
}
