
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        










.icon {
  width: 4rem;
  height: 4rem;
  fill: $c-dev-info;
}
