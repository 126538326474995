
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        







.webgl {
  @include get-all-space;

  position: fixed;
  z-index: -1;

  .canvas {
    @include get-all-space;
  }

  @include mq($until: l) {
    pointer-events: none;
  }
}
