
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        





.wysiwyg {
  .picture,
  figure {
    position: relative;
    height: 30rem;
    margin-top: $spacing * 3;
    margin-bottom: $spacing * 3;

    @include mq(l) {
      width: calc(100% + 64rem);
      height: 50rem;
      margin-right: -32rem;
      margin-left: -32rem;
    }
  }

  figure {
    img {
      @include image-fit;
    }
  }

  // Mixing style

  h2 + p,
  h2 + h3,
  h3 + p,
  h4 + p,
  p + p {
    margin-top: $spacing * 2;
  }

  p + h2,
  p + h3,
  p + h4 {
    margin-top: $spacing * 3;
  }

  ul {
    margin: $spacing * 2 0;
  }

  li {
    list-style: initial;
    margin-left: $spacing;
    padding-left: $spacing / 2;

    & + li {
      margin-top: $spacing;
    }
  }

  strong {
    color: $c-blue-dark;
  }

  .video-outer {
    margin: 2rem 0 4rem;
  }

  .video {
    transform: translateY(0);
  }
}
