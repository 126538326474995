
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        










































































.collapse {
  border-top: 1px solid $c-gray-lighter;
  border-bottom: 1px solid $c-gray-lighter;
}

.collapse__header {
  @extend %fw-bold;
  @extend %ff-alt;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing * 1.5 0;
  color: $c-blue-dark;
  user-select: none;
  font-size: 1.6rem;
  line-height: 1.4;
  transition: color 0.2s ease-out;
  cursor: pointer;
}

.collapse__header__content {
  flex-basis: 80%;
}

.collapse__header__subtitle {
  margin-top: $spacing / 2;
  color: $c-black;
  font-weight: 400;
}

.collapse__header__number {
  margin-top: 4px;
}

.collapse__header__icon-outer {
  position: relative;
  transform: translate(-2rem, 2px);
}

.collapse__header__icon {
  @include center-xy;

  z-index: 9;
  flex-shrink: 0;
  overflow: visible;
  width: 20px;
  height: 20px;
  fill: $c-blue-medium;
  transition: fill 0.2s ease-out;
  cursor: pointer;

  .collapse__header__title + & {
    margin-left: $spacing;
  }

  .collapse.is-open & {
    fill: $c-blue-light;
  }

  &.is-press {
    width: 4rem;
    height: 4rem;
    fill: $c-blue-medium;
  }

  &:hover {
    fill: $c-blue-light;
  }
}

.collapse__text {
  overflow: hidden;
  width: 80%;
  margin-bottom: $spacing * 1.5;

  @include mq(m) {
    width: 70%;
    margin-left: 10%;
  }
}

.collapse__text-inner {
  margin-bottom: $spacing;

  ul {
    margin: $spacing * 2 0;
  }

  li {
    list-style: initial;
    margin-left: $spacing;
    padding-left: $spacing / 2;

    & + li {
      margin-top: $spacing;
    }
  }
}
