
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        
























































































































.card {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  text-decoration: none;
  background-color: $c-gray-lightest;

  * {
    text-decoration: none;
  }

  &:hover {
    .card__picture {
      transform: scale(1.1);
    }
  }

  @include mq(m) {
    max-width: 40rem;
    transition: transform 0.5s $ease-out;

    &.lefted {
      transform: translateX(-120%);
    }
  }
}

.card__picture-outer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 20rem;

  @include mq(l) {
    height: 25rem;
  }
}

.card__picture {
  @include image-fit;

  // background-color: rgba($c-blue-medium, 0.1);
  transition: transform 0.2s ease-out;

  &.articles {
    display: none;
  }
}

.card__content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: $spacing;

  @include mq(l) {
    padding: $spacing * 2;
  }
}

.card__content__tag {
  @extend %fw-bold;

  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  padding: $spacing / 4 $spacing / 2;
  color: $c-white;
  font-size: 1.2rem;
  background-color: $c-blue-medium;

  &.is-turquoise {
    background-color: $c-blue-light;
  }
}

.card__content__title {
  line-height: 1.2;

  @include mq($until: l) {
    margin-top: $spacing * 2;
  }
}

.card__content__text {
  margin-top: $spacing;
  font-size: 1.4rem;
  line-height: 1.8;

  @include mq(l) {
    margin-top: $spacing;
  }
}

.card__content__date {
  margin-top: $spacing;
}

// Flickity related

.flickity-button {
  @extend %button-nostyle;

  position: relative;
  width: 6rem;
  height: 6rem;
  padding: 2rem;
  background-color: transparent;
  border: 1px solid $c-blue-medium;
  fill: $c-blue-medium;

  &.previous {
    left: 50%;
    transform: translateX(-100%);
  }

  &.next {
    left: 50%;
    fill: $c-white;
    background-color: $c-blue-medium;
    transform: translateX(-100%);
  }

  @include mq($until: l) {
    margin-top: $spacing * 2;
  }

  @include mq(l) {
    position: absolute;
    bottom: 0;

    &.previous {
      left: $spacing * 6;
      transform: translateX(0);
    }

    &.next {
      left: $spacing * 6;
      transform: translateX(100%);
    }
  }
}

.flickity-button-icon {
  @include center-xy;

  width: 1.5rem;
  height: 1.5rem;
}
