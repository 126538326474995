
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        







.tag {
  display: flex;
  align-items: center;
  padding: $spacing / 2.5 $spacing / 2;
  color: $c-blue-medium;
  background-color: $c-gray-lightest;
  border: 1px solid $c-blue-medium;
  transition: color 0.2s ease-out, border 0.2s ease-out;
}

.tag__label {
  @extend %text-uppercase;
  @extend %fw-bold;
  @extend %ff-alt;

  font-size: 1.2rem;
  line-height: 1em;
  white-space: nowrap;
  letter-spacing: 0.1em;
}
