
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        

















.tags {
  margin-top: $spacing * 2;
}

.tags__content {
  display: flex;
  justify-content: space-between;
  width: 100%;

  & + & {
    margin-top: $spacing * 2;
  }

  @include mq($until: m) {
    flex-direction: column;
  }
}

.tags__items {
  display: flex;
  flex-wrap: wrap;
}

.tags__item {
  margin-right: $spacing / 2;
  margin-bottom: $spacing / 2;
  color: $c-white;
}
