
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        






































































































/* stylelint-disable no-descending-specificity */
.mega-menu {
  position: absolute;
  z-index: 99;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.mega-menu__trigger {
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 100%;
  margin-left: auto;
  color: $c-white;
  text-decoration: none;
}

.mega-menu__trigger__hamburger {
  span {
    display: block;
    width: 20px;
    height: 2px;
    margin-bottom: 3px;
    background-color: $c-white;
    transition: transform 0.3s, opacity 0.4s;
  }

  .is-open & {
    span {
      background-color: $c-white;
    }

    span:nth-child(1) {
      /* stylelint-disable-next-line declaration-no-important */
      transform: translateY(5px) rotate(45deg) !important;
    }

    span:nth-child(2) {
      opacity: 0;
      /* stylelint-disable-next-line declaration-no-important */
      transform: translate(-20px) !important;
    }

    span:nth-child(3) {
      transform: translateY(-5px) rotate(-45deg);
    }
  }

  .mega-menu__trigger:hover & {
    span:nth-child(1) {
      transform: translate(-5px);
    }

    span:nth-child(2) {
      transform: translate(-10px);
    }
  }
}

.mega-menu__trigger__hamburger__close {
  position: relative;
  right: 2rem;
}

.mega-menu__trigger__label {
  @include mq($until: $header-breakpoint) {
    display: none;
  }
}

.mega-menu__container {
  @include get-all-space;

  display: none;
  overflow: scroll;
  width: 100%;
  min-height: 100vh;
  background-color: $c-blue-medium;
  transition: transform 0.15s;

  a {
    text-decoration: none;
  }

  .is-open & {
    display: block;
  }

  @include mq($until: nav) {
    &.is-sub-open {
      transform: translateX(-100%);
    }
  }
}

.mega-menu__container-inner {
  display: flex;
  flex-direction: column;
  // overflow: auto;
  height: 100%;
  // max-height: calc(100vh - #{$header-height});
  margin-top: $header-height;
  padding: $spacing * 3;

  @include mq(nav) {
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    margin: 0 auto;

    &.is-sub-open {
      transform: none;
    }
  }

  @include mq(xxxl) {
    padding-left: 14rem;
  }
}

.mega-menu__main {
  transition: transform 0.15s;

  @include mq(nav) {
    position: relative;
    flex-basis: 100%;
  }
}

.mega-menu__title {
  display: block;
  margin-bottom: 2rem;
  font-size: 1.6rem;
  font-weight: 500;
}

.mega-menu__sub-item {
  // position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  opacity: 0;

  .is-active & {
    z-index: 10;
    opacity: 1;
  }
}

.mega-menu__sub-item__close {
  display: flex;
  align-items: center;
  padding: 2rem;
  color: $c-gray;
  font-size: 1.5rem;
}

.mega-menu__sub-item__close__arrow {
  margin-right: 1rem;
  fill: $c-gray;
  transform: rotate(90deg);
}

.mega-menu__sub-item__container {
  overflow: auto;
  height: calc(100% - 7rem);
  padding: 2rem;
}

.mega-menu__small-label {
  color: $c-white;
}

.mega-menu__main__item {
  display: block;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.2;
  transition: opacity 0.3s $ease;

  > a {
    display: inline-flex;
    align-items: center;
    color: $c-white;
  }

  & + & {
    margin-top: $spacing * 1.5;
  }

  &.is-active {
    > a {
      color: $c-blue-dark;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.mega-menu__main__item__title {
  @extend %text-uppercase;

  & + & {
    margin-top: $spacing * 1.5;
  }
}

.mega-menu__main__item__subtitle {
  display: block;
  color: $c-white;
}

.mega-menu__main__item__arrow {
  margin-left: 1.5rem;
  fill: $c-blue-light;
  transition: transform 0.3s ease-out;

  .is-active & {
    transform: rotate(180deg);
  }
}

.mega-menu__sub-item__links__item {
  font-size: 1.8rem;

  a {
    color: $c-white;

    .icon {
      fill: $c-blue-medium;
      width: 2.4rem;
      opacity: 0;
      transition: transform 0.2s, opacity 0.1s;
    }
  }

  ::v-deep [class*='action--link'] {
    /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
    .action__label {
      color: $c-black !important; // stylelint-disable-line declaration-no-important
    }

    /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
    .icon {
      fill: $c-blue-medium !important; // stylelint-disable-line declaration-no-important
    }
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  @include mq(#{$header-breakpoint}) {
    margin-bottom: 2rem;
    color: $c-white;

    a:hover {
      .icon {
        opacity: 1;
        transform: translateX(8px);
      }
    }
  }
}

.mega-menu__secondary {
  margin-top: $spacing * 1.5;
  transition: transform 0.15s;

  @include mq(nav) {
    position: relative;
    flex-basis: 100%;
  }
}

.mega-menu__secondary__item {
  display: block;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.2;
  transition: opacity 0.3s $ease;

  > a {
    display: inline-flex;
    align-items: center;
    color: $c-blue-dark;
  }

  & + & {
    margin-top: $spacing;
  }

  &:hover {
    text-decoration: none;
  }
}
/* stylelint-enable no-descending-specificity */
