
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        














.picture {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 20rem;
}

.picture-inner {
  @include image-fit;

  // transition: transform 0.2s ease-out;
}
