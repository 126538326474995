.h-wrapper,
[class*='h-wrapper--'] {
  max-width: $content-width;
  margin-right: $spacing * 2;
  margin-left: $spacing * 2;

  @include mq(m) {
    margin-right: $spacing * 3;
    margin-left: $spacing * 3;
  }

  @include mq(xl) {
    margin-right: $spacing * 6;
    margin-left: $spacing * 6;
  }

  @include mq(xxl) {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}
